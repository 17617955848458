// header {
//   background-color: $header-background-color;
//   box-shadow:
//     $effect-elevation-light-03-0-offset-x + px $effect-elevation-light-03-0-offset-y + px $effect-elevation-light-03-0-radius + px $effect-elevation-light-03-0-color,
//     $effect-elevation-light-03-0-offset-x + px $effect-elevation-light-03-0-offset-y + px $effect-elevation-light-03-0-radius + px $effect-elevation-light-03-0-color;
//   width: 100% !important;
//   z-index: 10;
//   padding-left: 0 !important;
// }

header {
      background-color: $header-background-color;
      box-shadow:
        $effect-elevation-light-03-0-offset-x + px $effect-elevation-light-03-0-offset-y + px $effect-elevation-light-03-0-radius + px $effect-elevation-light-03-0-color,
        $effect-elevation-light-03-0-offset-x + px $effect-elevation-light-03-0-offset-y + px $effect-elevation-light-03-0-radius + px $effect-elevation-light-03-0-color;
      width: 100% !important;
      z-index: 10;
      padding-left: 16px;
      .nav__link.active {
        color: $color-primary-light !important;
        }
      .level_1{
        height: 80px;
        display: flex;
        align-items: center;
        background-color: $header-background-color !important;
        .nav__link.trail {
          color: $color-primary-light !important;
        }
        .level_2 {
          width: max-content;
          background-color: $header-background-color;
          .nav__item {
            height: 50px;
            text-align: left;
            width: 100%;
            min-width: 205px;
            background-color: $header-background-color !important;
            color:$color-primary;
            
            @media (max-width:767px) {
              height: auto;
              color:$color-primary;
            }
            &:hover {
              color: $color-primary-light !important;
              .nav__link {
                color:$color-primary-light !important;
              }
            }
            
          }
        }
        .nav__item.trail {
          color: $color-primary-light; 
          background-color: $color-white !important;
        }
        .nav__item {
          height: 80px;
          display: flex;
          align-items: center;
          
          @media (max-width:990px) {
            height: auto;
            display: block;
          }
          &:focus {
            color: $color-primary-dark;
            background-color: $color-white !important;
            .nav__link {
              &:hover {
              color: $color-primary-light;
              text-decoration: none;
              }
            }
          }
          &:hover {
            color: $color-primary-light;
           
            .nav__link {
              color: $color-primary-light;
              &:hover {
              text-decoration: none;
              }
            }
            .nav__link.submenu {
              &:after {
                font-family: 'iconfont' !important;
                speak: never;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                line-height: 1;
                text-transform: none;
                content: "\e909";
                padding-left: 10px;
                width: 12px;
                height: 6.58px;
                @media (max-width:990px) {
                 // display: none !important;
                }
              }
            }
          }
          .nav__link.submenu {
            &:after {
              font-family: 'iconfont' !important;
              speak: never;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              line-height: 1;
              text-transform: none;
              content: "\e906";
              padding-left: 10px;
              width: 12px;
              height: 6.58px;
              @media (max-width:990px) {
               // display: none !important;
               
              }
            }
          }
          
        }
      }
      .level_2 {
        width: max-content;
        background-color: $color-white;
        .nav__item {
          height: 50px;
          text-align: left;
          width: 100%;
          min-width: 205px;
          background-color: $color-white !important;
          color:$color-primary;
          
          @media (max-width:990px) {
            height: auto;
  
            .nav__link {
              color:$color-primary;
            }
          }
          :hover {
            color: $color-primary-light !important;
          }
          
        }
      }
    }


  .page--accent {
    h1, .h1 ,h2, .h2 , h3 , .h3 , .xlarge , h4 , .h4 , pre {
      color: $color-accent ;
    }
    .contact__Wrapper {
      background-color: $color-accent;
    }
  }  

  .page--accent-2 {
    h1, .h1,h2, .h2 , h3 , .h3 , .xlarge , h4 , .h4 , pre {
      color: $color-accent-2 ;
    }
    .contact__Wrapper {
      background-color: $color-accent-2;
    }
  } 

  .page--accent-3 {
    h1, .h1, h2, .h2 , h3 , .h3 , .xlarge , h4 , .h4 , pre {
      color: $color-accent3 ;
    }
    .contact__Wrapper {
      background-color: $color-accent3;
    }
  } 
// .selected--index,.selected--startseite,.selected--termin, .selected--login {
//   .card_modal {
//     background-color: $color-secondary ;
//     color: $color-white;
//   }
//   .font-size-display-1, .font-size-display-1 p {
//     color:$color-secondary !important;
//   }
//   .header__Wrapper {
//     background-color: $color-secondary !important;
//   }
//   header {
//     background-color: $color-secondary !important;
//     .level_1{
//       height: 80px;
//       display: flex;
//       align-items: center;
//       background-color: $color-secondary !important;
//       .level_2 {
//         width: max-content;
//         background-color: $color-white;
//         .nav__item {
//           height: 50px;
//           text-align: left;
//           width: 100%;
//           min-width: 205px;
//           background-color: $color-white !important;
//           color:$color-secondary;
          
//           @media (max-width:767px) {
//             height: auto;
//             color:$color-secondary !important;
//           }
//           &:hover {
//             background-color: $color-secondary !important;
//             color: $color-white !important;
//             .nav__link {
//               background-color: $color-secondary !important;
//               color:$color-white !important;
//             }
//           }
          
//         }
//       }
//       .nav__item.trail {
//         color: $color-secondary !important;
//         background-color: $color-white !important;
//       }
//       .nav__item {
//         height: 80px;
//         display: flex;
//         align-items: center;
        
//         @media (max-width:990px) {
//           height: auto;
//           display: block;
//           background-color: $color-secondary !important;
//         }
//         &:focus {
//           color: $color-secondary !important;
//           background-color: $color-white !important;
//           .nav__link {
//             &:hover {
//             color: $color-secondary !important;
//             text-decoration: none;
//             }
//           }
//         }
//         &:hover {
//           color: $color-secondary !important;
//           background-color: $color-white !important;
//           .nav__link {
//             color: $color-secondary !important;
//             background-color: $color-white !important;
//             &:hover {
//             text-decoration: none;
//             }
//           }
//           .nav__link.submenu {
//             &:after {
//               font-family: 'iconfont' !important;
//               speak: never;
//               font-style: normal;
//               font-weight: normal;
//               font-variant: normal;
//               line-height: 1;
//               text-transform: none;
//               content: "\e909";
//               padding-left: 10px;
//               width: 12px;
//               height: 6.58px;
//               @media (max-width:990px) {
//                 display: none !important;
//               }
//             }
//           }
//         }
//         .nav__link.submenu {
//           &:after {
//             font-family: 'iconfont' !important;
//             speak: never;
//             font-style: normal;
//             font-weight: normal;
//             font-variant: normal;
//             line-height: 1;
//             text-transform: none;
//             content: "\e906";
//             padding-left: 10px;
//             width: 12px;
//             height: 6.58px;
//             @media (max-width:990px) {
//               display: none !important;
//             }
//           }
//         }
        
//       }
//     }
//     .level_2 {
//       width: max-content;
//       background-color: $color-white;
//       .nav__item {
//         height: 50px;
//         text-align: left;
//         width: 100%;
//         min-width: 205px;
//         background-color: $color-white !important;
//         color:$color-secondary;
        
//         @media (max-width:990px) {
//           height: auto;

//           .nav__link {
//             color:$color-secondary !important;
//           }
//         }
//         :hover {
//           background-color: $color-secondary !important;
//           color: $color-white !important;
//         }
        
//       }
//     }
//   }
// }
// @media (max-width:770px){
//   .selected--startseite .level_2 .nav__link {
//     color: $color-secondary !important;
//   }
// }

// .selected--bementee , .selected--blue {
//   .font-size-display-1, .font-size-display-1 p {
//     color:$color-info !important;
//   }
//   .header__Wrapper {
//     background-color: $color-info !important;
//   }
//   header {
//     background-color: $color-secondary !important;
//     .nav__item.active {
//       background-color: $color-info !important;
//     }
//     .nav__link.active {
//       color: $color-white !important;
//       font-weight: $font-large-bold-font-weight;
//     }
//     .level_1{
//       height: 80px;
//       display: flex;
//       align-items: center;
//       background-color: $color-secondary !important;
//       .nav__item.trail {
//         color: $color-white !important;
//         background-color: $color-info !important;
//       }
//       .nav__item {
//         height: 80px;
//         display: flex;
//         align-items: center;
//         background-color: $color-secondary;
//         @media (max-width:990px) {
//           height: auto;
//           display: block;
//         }
//         &:focus {
//           color: $color-white !important;
//           background-color: $color-info !important;
//           .nav__link {
//             &:hover {
//             color: $color-white !important;
//             text-decoration: none;
//             }
//           }
//         }
//         &:hover {
//           color: $color-white !important;
//           background-color: $color-info !important;
//           .nav__link {
//             &:hover {
//             color: $color-white !important;
//             text-decoration: none;
//             }
//           }
//           .nav__link.submenu {
//             &:after {
//               font-family: 'iconfont' !important;
//               speak: never;
//               font-style: normal;
//               font-weight: normal;
//               font-variant: normal;
//               line-height: 1;
//               text-transform: none;
//               content: "\e909";
//               padding-left: 10px;
//               width: 12px;
//               height: 6.58px;
//               @media (max-width:990px) {
//                 display: none !important;
//               }
//             }
//           }
//         }
        
//         .nav__link.submenu {
//           &:after {
//             font-family: 'iconfont' !important;
//             speak: never;
//             font-style: normal;
//             font-weight: normal;
//             font-variant: normal;
//             line-height: 1;
//             text-transform: none;
//             content: "\e906";
//             padding-left: 10px;
//             width: 12px;
//             height: 6.58px;
//             @media (max-width:990px) {
//               display: none !important;
//             }
//           }
//         }

//       }
//     }
//     .level_2 {
//       width: max-content;
//       background-color: $color-white;
//       .nav__item {
//         height: 50px;
//         text-align: left;
//         width: 100%;
//         min-width: 205px;
//         color: $color-info !important;
//         background-color: $color-white;
//         @media (max-width:990px) {
//           height: auto;
//         }
//         &:hover {
//           .nav__link {
//             color: $color-white !important;
//           }
//         }
//         .nav__link.active {
//           color: $color-white !important;
//         }
//         .nav__link {
//           color: $color-info !important;
//         }
//       }
//     }
//   }
// }

// .selected--mentor-innenprogramm ,.selected--red {
//   .fill-color-primery {
//     fill: $color-secondary !important;
//   }
//   .font-size-display-1, .font-size-display-1 p {
//     color:$color-primary !important;
//   }
//   .header__Wrapper {
//     background-color: $color-primary !important;
//   }
//   header {
//     background-color: $color-secondary !important;
//     .nav__item.active {
//       background-color: $color-primary !important;
//     }
//     .nav__link.active {
//       color: $color-white !important;
//       font-weight: $font-large-bold-font-weight;
//     }
//     .level_1{
//       height: 80px;
//       display: flex;
//       align-items: center;
//       background-color: $color-secondary;
//       .nav__item.trail {
//         color: $color-white !important;
//         background-color: $color-primary !important;
//       }
//       .nav__item {
//         height: 80px;
//         display: flex;
//         align-items: center;
//         background-color: $color-secondary;
//         @media (max-width:990px) {
//           height: auto;
//           display: block;
//         }
//         &:focus {
//           color: $color-white !important;
//           background-color: $color-primary !important;
//           .nav__link {
//             &:hover {
//             color: $color-white !important;
//             text-decoration: none;
//             }
//           }
//         }
//         &:hover {
//           color: $color-white !important;
//           background-color: $color-primary !important;
//           .nav__link {
//             &:hover {
//             color: $color-white !important;
//             text-decoration: none;
//             }
//           }
//           .nav__link.submenu {
//             &:after {
//               font-family: 'iconfont' !important;
//               speak: never;
//               font-style: normal;
//               font-weight: normal;
//               font-variant: normal;
//               line-height: 1;
//               text-transform: none;
//               content: "\e909";
//               padding-left: 10px;
//               width: 12px;
//               height: 6.58px;
//               @media (max-width:990px) {
//                 display: none !important;
//               }
//             }
//           }
//         }
        
//         .nav__link.submenu {
//           &:after {
//             font-family: 'iconfont' !important;
//             speak: never;
//             font-style: normal;
//             font-weight: normal;
//             font-variant: normal;
//             line-height: 1;
//             text-transform: none;
//             content: "\e906";
//             padding-left: 10px;
//             width: 12px;
//             height: 6.58px;
//             @media (max-width:990px) {
//               display: none !important;
//             }
//           }
//         }

        
//       }
//     }
//     .level_2 {
//       width: max-content;
//       background-color: $color-white;
//       .nav__item {
//         height: 50px;
//         text-align: left;
//         width: 100%;
//         min-width: 205px;
//         color: $color-primary !important;
//         background-color: $color-white;
//         @media (max-width:990px) {
//           height: auto;
//         }
//         &:hover {
//           .nav__link {
//             color: $color-white !important;
//           }
//         }
//         .nav__link.active {
//           color: $color-white !important;
//         }
//         .nav__link {
//           color: $color-primary !important;
//         }
//       }
//     }
//   }
// }

// .selected--ueber-uns, .selected--orange {
//   .fill-color-accent {
//     fill: $color-info !important;

//   }
//   .fill-color-primery {
//     fill: $color-info !important;
//   }
//   .font-size-display-1, .font-size-display-1 p {
//     color:$color-accent !important;
//   }
//   .header__Wrapper {
//     background-color: $color-accent !important;
//   }
//   header {
//     background-color: $color-secondary !important;
//     .nav__item.active {
//       background-color: $color-accent !important;
//     }
//     .nav__link.active {
//       color: $color-white !important;
//       font-weight: $font-large-bold-font-weight;
//     }
//     .level_1{
//       height: 80px;
//       display: flex;
//       align-items: center;
//       background-color: $color-secondary !important;
//       .nav__item.trail {
//         color: $color-white !important;
//         background-color: $color-accent !important;
//       }
//       .nav__item {
//         height: 80px;
//         display: flex;
//         align-items: center;
//         background-color: $color-secondary;
//         @media (max-width:990px) {
//           height: auto;
//           display: block;
//         }
//         &:focus {
//           color: $color-white !important;
//           background-color: $color-accent !important;
//           .nav__link {
//             &:hover {
//             color: $color-white !important;
//             text-decoration: none;
//             }
//           }
//         }
//         &:hover {
//           color: $color-white !important;
//           background-color: $color-accent !important;
//           .nav__link {
//             &:hover {
//             color: $color-white !important;
//             text-decoration: none;
//             }
//           }
//           .nav__link.submenu {
//             &:after {
//               font-family: 'iconfont' !important;
//               speak: never;
//               font-style: normal;
//               font-weight: normal;
//               font-variant: normal;
//               line-height: 1;
//               text-transform: none;
//               content: "\e909";
//               padding-left: 10px;
//               width: 12px;
//               height: 6.58px;
//               @media (max-width:990px) {
//                 display: none !important;
//               }
//             }
//           }
//         }
        
//         .nav__link.submenu {
//           &:after {
//             font-family: 'iconfont' !important;
//             speak: never;
//             font-style: normal;
//             font-weight: normal;
//             font-variant: normal;
//             line-height: 1;
//             text-transform: none;
//             content: "\e906";
//             padding-left: 10px;
//             width: 12px;
//             height: 6.58px;
//             @media (max-width:990px) {
//               display: none !important;
//             }
//           }
//         }
        
//       }
//     }
//     .level_2 {
//       width: max-content;
//       background-color: $color-white;
//       .nav__item {
//         height: 50px;
//         text-align: left;
//         width: 100%;
//         min-width: 205px;
//         color: $color-accent !important;
//         background-color: $color-white;
//         @media (max-width:990px) {
//           height: auto;
//         }
//         &:hover {
//           .nav__link {
//             color: $color-white !important;
//           }
//         }
//         .nav__link.active {
//           color: $color-white !important;
//         }
//         .nav__link {
//           color: $color-accent !important;
//         }
//       }
//     }
//   }
// }

// .selected--yellow {
//   .fill-color-accent {
//     fill: $color-yellow !important;

//   }
//   .fill-color-primery {
//     fill: $color-yellow !important;
//   }
//   .font-size-display-1, .font-size-display-1 p {
//     color:$color-yellow !important;
//   }
//   .header__Wrapper {
//     background-color: $color-yellow !important;
//     @media (max-width:767px){
//       .header__title {
//         color:#005CA3 !important;
//       }
//     }
//   }
//   header {
//     background-color: $color-secondary !important;
//     .nav__item.active {
//       background-color: $color-yellow !important;
//     }
//     .nav__link.active {
//       color: $color-white !important;
//       font-weight: $font-large-bold-font-weight;
//     }
//     .level_1{
//       height: 80px;
//       display: flex;
//       align-items: center;
//       background-color: $color-secondary !important;
//       .nav__item.trail {
//         color: $color-white !important;
//         background-color: $color-yellow !important;
//       }
//       .nav__item {
//         height: 80px;
//         display: flex;
//         align-items: center;
//         background-color: $color-secondary;
//         @media (max-width:990px) {
//           height: auto;
//           display: block;
//         }
//         &:focus {
//           color: $color-white !important;
//           background-color: $color-yellow !important;
//           .nav__link {
//             &:hover {
//             color: $color-white !important;
//             text-decoration: none;
//             }
//           }
//         }
//         &:hover {
//           color: $color-white !important;
//           background-color: $color-yellow !important;
//           .nav__link {
//             &:hover {
//             color: $color-white !important;
//             text-decoration: none;
//             }
//           }
//           .nav__link.submenu {
//             &:after {
//               font-family: 'iconfont' !important;
//               speak: never;
//               font-style: normal;
//               font-weight: normal;
//               font-variant: normal;
//               line-height: 1;
//               text-transform: none;
//               content: "\e909";
//               padding-left: 10px;
//               width: 12px;
//               height: 6.58px;
//               @media (max-width:990px) {
//                 display: none !important;
//               }
//             }
//           }
//         }
        
//         .nav__link.submenu {
//           &:after {
//             font-family: 'iconfont' !important;
//             speak: never;
//             font-style: normal;
//             font-weight: normal;
//             font-variant: normal;
//             line-height: 1;
//             text-transform: none;
//             content: "\e906";
//             padding-left: 10px;
//             width: 12px;
//             height: 6.58px;
//             @media (max-width:990px) {
//               display: none !important;
//             }
//           }
//         }
        
//       }
//     }
//     .level_2 {
//       width: max-content;
//       background-color: $color-white;
//       .nav__item {
//         height: 50px;
//         text-align: left;
//         width: 100%;
//         min-width: 205px;
//         color: $color-yellow !important;
//         background-color: $color-white;
//         @media (max-width:990px) {
//           height: auto;
//         }
//         &:hover {
//           .nav__link {
//             color: $color-white !important;
//           }
//         }
//         .nav__link.active {
//           color: $color-white !important;
//         }
//         .nav__link {
//           color: $color-yellow !important;
//         }
//       }
//     }
//   }
// }
// .header__Wrapper {
//   .header__title {
//     position: absolute;
//     max-width: 450px;
//     margin: 0 auto;
//     margin-right: 511px;
//     text-align: left;
//     color:#005CA3;
//   }
  
//   @media (max-width:1330px) {
//     .header__title {
//       position: absolute;
//       max-width: 405px;
//       margin: 0 auto;
//       margin-right: 511px;
//       text-align: left;
//       color:#005CA3;
//     }
//   }
//   @media (max-width:820px) {
//     .header__title {
//       position: absolute;
//       max-width: 405px;
//       margin: 0 auto;
//     margin-right: 472px;
//       text-align: left;
//       color:#005CA3;
//     }
//   }
//   // @media (max-width:1240px) {
//   //   .header__title {
//   //   margin-right: 486px;
//   //   }
//   // }

//   height: 450px;
//   border-radius: $radius-bottom-left;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;

//   .header__svg__mobile {
//     position: absolute;
//     right: 0;
//     top: 100px;
//     .clipped-img {
//       height: auto !important;
//       width: 660px !important;
//     }
//   }
//   .header__svg__desktop,
//   .header__svg__xs {
//     display: none;
//   }

// }
// @media (max-width:767px){
//   .header__Wrapper {
//     height: 730px;
//     .header__title {
//       position: sticky;
//       top: 550;
//       left: 0;
//       margin: 0 auto;
//       display: flex;
//       justify-content: right;
//       align-items: self-end;
//       margin-bottom: 3rem;
//       z-index: 9;
//       color:$color-white;
//       text-align: center;
//     }
//   }
// }
// @media (max-width:435px){
//   .header__svg__desktop,
//   .header__svg__mobile {
//     display: none;
//   }
 
//   .header__svg__xs {
//     display:block !important;
//     position: absolute;
//     right: 0;
//     top: 100px;
//     .clipped-img {
//       height: auto !important;
//       width: 600px !important;
//     }
//   }
// }
