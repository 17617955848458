/* -------------------------------------------------------------
//  Carousel
// -----------------------------------------------------------*/
$themeColor: $color-primary !default;
.swiper {
  
  height: 650px;


  &-pagination-dots {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 3;
  }

  &-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: to-rem($font-medium-line-height);
  }

  &-button-prev {
    right:50%;
    left:unset;
    color: $color-primary !important;
    background-color: $color-white !important;
    z-index: 2;
    text-transform: uppercase;
    opacity: 1;
    cursor: pointer;
    line-height: 1;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 7.29px;
    width: 44px;
    font-size: 32px;
  }

  &-button-next {
/* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 7.29px;
    left: 50%;
    right:unset;
    color: $themeColor !important;
    background-color: #fff!important;
    z-index: 2;
    text-transform: uppercase;
    opacity: 1;
    cursor: pointer;
    line-height: 1;
    width: 44px;
    font-size: 32px;
  }
}
.swiper-wrapper {
  max-height: 600px;
}
.swiper-slide {
  text-align: center;
  font-size: 32px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 32px !important;
}

.swiper__arrows--modile {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}


.swiper-button-prev::before {
}


.swiper-button-next::before  {

}
span.swiper-pagination-bullet {
  background: $color-primary-light;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50px;

}
span.swiper-pagination-bullet-active {
  background: $themeColor !important;
}
.swiper-pagination {
  text-align: center;
  transition: .3s opacity;
  transform: translate3d(0,0,0);
  z-index: 10;

  display: flex;
  gap: 10px;
}


.swiper__info__box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  width: 399.67px;
  height: 191px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgb(40 41 61 / 4%), 0px 16px 24px rgb(96 97 112 / 16%);
  border-radius: 20px;
  position: absolute;
  bottom: 110;
  z-index: 1;
  right: 60px;

  @media (max-width:770px) {
    right:15%
  }

  @media (max-width:430px) {
    right:15px;
  }
  @media (max-width:410px) {
    right:15%;
    width: 290px;
  }
  .info__box--logo {
    display: flex;
    flex-wrap: nowrap;
    gap: 20px;
    margin-top: 10px;
    padding-left: 10px;
    img {
      width: 63px;
      height: 79px;
      margin-bottom: 20px;
    }
    h4 {
      margin-top: 0;
    }
  }

  .info__box--tel {
    .icon-phone {
      padding-right: 12px;
      font-size: 26;
      font-weight: 700;
      padding-left: 12px;
    }
    a {    
      text-decoration: none;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: $color-primary-light;
      }
    }
  }
  
}



