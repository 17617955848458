/*
Author: click solutions GmbH
Marco Both, Stephan Loewe
Date: Ver1.0 - 21.04.2022
Project: HaBeMa Website

Media:       all (screen, projector, print)
Responsive:  yes
Breakpoints: X-Small <576px
             Small   ≥576px
             Medium  ≥768px
             Large   ≥992px
             Extra large ≥1200px
             Extra extra large  ≥1400px
*/

// SETTINGS
@import 'settings/tokens_default';
@import 'settings/tokens';
@import 'settings/config';

@import 'settings/animations';

@import 'settings/fonts';
@import 'settings/iconfont';
@import 'settings/spacings';

// TOOLS
//@import 'tools/luminance';
//@import 'tools/a11y-color';
@import 'tools/clamp-element';
//@import 'tools/text-color-lightness';
@import 'tools/to-rem';

// GENERIC
@import 'generic/reset';
@import 'vendor/glide.core';
@import 'vendor/glide.theme';

// ELEMENTS
@import 'elements/body';
@import 'elements/headings';
@import 'elements/images';
@import 'elements/links';
@import 'elements/lists';
@import 'elements/logo';
@import 'elements/paragraph';
@import 'elements/section';
@import 'elements/rsce_features_item';
@import 'elements/rsce_card';

// OBJECTS
@import 'vendor/bootstrap-grid.min';

// COMPONENTS
@import 'components/accordion';
@import 'components/buttons';
@import 'components/checkbox';
//@import 'components/forms';
@import 'components/cookie-banner';
//@import 'components/downloads';
@import 'components/footer';
@import 'components/header';
@import 'components/navigation';
@import 'components/radio';
@import 'components/carousel';
@import 'components/news';
@import 'components/event';


// UTILITIES
@import 'utilities/helper';
@import 'utilities/colors';