.eventlist {
    margin-left: 0 !important;
    .eventlist__item {
        background: $color-black-01;
        border: 1px solid $color-black-50;
        box-shadow: 0px 0px 1px rgba(0, 69, 111, 0.08), 0px 0.5px 2px rgba(0, 69, 111, 0.08);
        border-radius: $radius-half;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        list-style: none !important;
        margin-left: 0 !important;
        margin-bottom: 60px;
        overflow: hidden;
        .eventlist__item--text {
            padding-top: 75px;
            padding-left: 60px;
            padding-bottom: 50px;
            padding-right: 100px;
            .event_datum {
                font-family: 'Sarala';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 32px;
                color: #FFFFFF;
                background: #00456F;
                border-radius: 4px;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 5px 10px;
                gap: 10px;

                width: 209px;
                height: 42px;
                margin-bottom: 29px;
            }
        }
        .eventlist__item--image{
            img {
                height: 479px;
                object-fit: cover;
            }
        } 
    }
    
   

}