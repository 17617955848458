/* -------------------------------------------------------------
//  Images
// -----------------------------------------------------------*/
img {
  height: auto;
  margin-bottom: to-rem($font-medium-line-height);
  width: 100%;
}
.image_container {
  margin: 0;
}
.radius-top-bottom-full {
 border-radius: $radius-top-bottom-full;
}

.avatar {
  width: 210px;
  height: 210px;
  border-radius: 50%;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}

ul.gallery__img {
  margin: 0 !important;
  gap:30px; 
  width: 100%;

  li {
    list-style: none !important;
    margin: 0 !important;
    padding: 0 !important;
    flex-grow: 1;
    width: 48%;
    img {
      height: 263.97px;
      border-radius: 10px;
      margin-bottom: 0;
      object-fit: cover;
    }
  }
}