/* -------------------------------------------------------------
//  Links
// -----------------------------------------------------------*/
.link {
  text-decoration: none;

  &:hover {
    border-bottom: 1px solid $link-color-dark;
  }
}


.mod_article a {
  text-decoration: none !important; 
  font-size: to-rem($font-small-font-size);
  line-height: to-rem($font-small-line-height);
  margin-bottom: to-rem($font-small-line-height);
  font-weight: 500;
  color: $color-primary; 
  border-bottom: 1px solid $color-primary;

  &:hover {
    color: $color-primary-light !important; 
    border-bottom: 1px solid $color-primary-light;
  }

}