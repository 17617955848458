.card_modal {
  background-color: $color-secondary;
  color:$color-white;
  border-radius: $radius-top-bottom-full;
  height: auto;
  min-height: 350px;
  overflow: hidden;
  box-shadow: 0px 2px 4px rgba(0, 69, 111, 0.08), 0px 8px 16px rgba(0, 69, 111, 0.08);
  img {
      object-fit: cover;
      height: 383px;
      margin: 0;
      transition:all 0.6s ease;
      filter:none;
      
    }
  @media (max-width:990px) {
    img {
      height: 383px;
    }
  } 
  @media (max-width:450px) {
    img {
      height: 254px;
    }
  }   
   .card_module--text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px 30px 10px;
    gap: 10px;
     & > div {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      ul {
        margin-left: 1rem;
      }
     }
     h3 {
      overflow-wrap: break-word;
      hyphens: manual;
      font-size: clamp(1.2rem, 1.2rem + 14 * (100vw - 375px) / 1065, 2.275rem);
    }
   } 

   &:hover {
  //   background-color: #cdf0ff !important;
  //   color: #00456f !important;

     a.btn.btn-arrow.btn--secondary {
       color:#00456f !important;
       background: white !important;
       }
    // .btn {
    //   border-color: $color-primary;
    //   color:$color-primary;
    // }
    // .btn--secondary {
    //   color:$color-primary;
    // &::before {
    //   color:$color-white !important;
    // background-color: $color-primary !important;
    // }
  //   // }
  //   img{
  //     filter:opacity(50%);
  //   }
   }
  
  // &:focus,&:active,&:visited {
  //   background-color: $color-secondary-dark !important;
  //   .btn--secondary {
  //     color:$color-white;
  //     &::before {
  //       color:$color-secondary-dark !important;
  //     background-color: $color-white !important;
  //     }
  //   }
  //   img{
  //     filter:opacity(50%);
  //   }
  // } 
}

.card_extralink {
  background-color: $color-black-01;
  color:$color-secondary;
  border-radius: $radius-top-bottom-half;
  height: auto;
  overflow: hidden;
  border: 1px solid $color-black-40;
  box-shadow: 0px 2px 4px rgba(0, 69, 111, 0.08), 0px 8px 16px rgba(0, 69, 111, 0.08);
  .card_module--text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    gap: 10px;
    
    .btn--secondary {
      color:$color-secondary;
      &::before {
        color:$color-white !important;
      background-color: $color-secondary !important;
      }
    }
   } 


  &:hover {
    background-color: $color-secondary-dark;
    color:$color-white;
    .btn--secondary {
      color:$color-white;
    &::before {
      color:$color-secondary-dark !important;
    background-color: $color-white !important;
    }
    }
  }
  
  &:focus,&:active,&:visited {
    background-color: $color-secondary-dark;
    color:$color-white;
    .btn--secondary {
      color:$color-white;
    &::before {
      color:$color-secondary-dark !important;
    background-color: $color-white !important;
    }
    }
  } 
}

.card_projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 40px;
  background: $color-black-01;
  border: 1px solid $color-black-50;
  border-radius: $radius-half;

  .card_projects--text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
  }
}

.card_modal.card_module--full {
  min-height: auto !important;
  .card_module--text {
    min-height: auto !important;
}
}

.card_module--text {
  min-height: auto;
  // @media (min-width:1400px) {
  //   min-height: 560px;
  // }
  // @media (min-width:990px) {
  //   min-height: 585px;
  // }
}



.card_modal.col-12.bg-rot {
  background:$color-primary;
}

.card_modal.col-12.bg-gelb {
  background:$color-yellow;
}

.card_modal.col-12.bg-orang {
  background:$color-accent;
}


.card_modal.col-12.bg-hellblau {
  background:$color-info;
}
.card_modal.col-12.bg-blau {
  background:$color-secondary;
}
.card_modal.col-12.bg-dunkelblau {
  background:$color-secondary-dark;
}



.row.contact__person h3 {
  word-break: keep-all;
  width: max-content;
  font-size: 1.6em !important;
}

@media (max-width:360px) {
  .row.contact__person h3 {
    word-break: keep-all;
    width: max-content;
    font-size: 1.4em;
  }
}