.logo {
  display: block;
  margin: 0;
  width: 171px;
  z-index: 99;

  img {
    margin-bottom: 0;
  }
}
.logo--footer {
  width: 170px;
}
@media only screen and (min-width: 768px) {
  .logo {
    width: 210px;
  }
}
