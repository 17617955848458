p {
  font-size: to-rem($font-medium-font-size);
  line-height: to-rem($font-medium-line-height);
  margin-bottom: to-rem($font-medium-line-height);
}

p.lead {
  font-size: to-rem($font-xlarge-font-size);
  line-height: to-rem($font-xlarge-line-height);
  margin-bottom: to-rem($font-xlarge-line-height);
}
p.strong {
  font-weight: 700;
}
p.large,
.large {
  font-family: $font-large-font-family;
  font-size: to-rem($font-large-font-size);
  line-height: to-rem($font-large-line-height);
  margin-bottom: to-rem($font-large-line-height);
}
.large.strong {
  font-family: $font-large-bold-font-family;
  font-size: to-rem($font-large-bold-font-size);
  line-height: to-rem($font-large-bold-line-height);
  margin-bottom: to-rem($font-large-bold-line-height);
  font-weight: $font-large-bold-font-weight;
}
p.medium,
.medium, .medium p {
  font-family: $font-medium-font-family;
  font-size: to-rem($font-medium-font-size);
  line-height: to-rem($font-medium-line-height);
  margin-bottom: to-rem($font-medium-line-height);
  font-weight:$font-medium-font-weight ;
}

.medium.strong {
  font-family: $font-medium-bold-font-family;
  font-size: to-rem($font-medium-bold-font-size);
  line-height: to-rem($font-medium-bold-line-height);
  margin-bottom: to-rem($font-medium-bold-line-height);
  font-weight:$font-medium-bold-font-weight ;
}
p.small,
small {
  font-size: to-rem($font-small-font-size);
  line-height: to-rem($font-small-line-height);
  margin-bottom: to-rem($font-small-line-height);
}
p.xxlarge ,.xxlarge p
{
  font-family: $font-xxlarge-font-family;
  font-size: to-rem($font-xxlarge-font-size);
  line-height: to-rem($font-xxlarge-line-height);
  font-weight: 700;
}

p.xxlarge.strong , p.xxlarge.strong a {
  font-size: to-rem($font-xxlarge-bold-font-size);
  line-height: to-rem($font-xxlarge-bold-line-height);
  border-bottom: none !important;
}

p.xlarge, 
pre {
  font-family: $font-xlarge-font-family !important;
  font-size: to-rem($font-xlarge-font-size);
  line-height: to-rem($font-xlarge-line-height);
  white-space: break-spaces;
}

p.xlarge.strong {
  font-size: to-rem($font-xlarge-bold-font-size);
  line-height: to-rem($font-xlarge-bold-line-height);
}

strong {
  font-weight: 700;
}

.col-12 > p {
  margin-top: 0; // resets margin of first paragraph
}

.team__wrapper--text {
  .medium {
    ul {
      margin-top: 1rem;
      margin-left: 1.25rem;
      max-width: 89%;
      li {
        font-family: $font-medium-font-family;
        font-size: to-rem($font-medium-font-size);
        line-height: to-rem($font-medium-line-height);
        font-weight:$font-medium-font-weight ;
      }
    }
  } 
} 