footer {
  background-color: $footer-background-color;
  color: $footer-text-color;
  padding: to-rem($sizes-section) 0 to-rem($sizes-section);
  margin-top: auto;
  // .col-md-4 {
  //   width: 27% !important;
  // }
  // .col-md-5 {
  //   width: 42% !important;
  // }
  // .col-md-2 {
  //   width: 23% !important;
  // }
  // .col-md-1 {
  //   width: 7% !important;
  // }
  .footer_menu_legel ul {
    display: flex;
    gap: 20px;
  }

  .footer_menu_legel ul.nav__list.level_1 li.nav__item {
    display: flex !important;
    flex-wrap: wrap;
    max-width: fit-content;
    

    .link--footer {
      font-size: to-rem($font-small-bold-font-size) !important;
      line-height: to-rem($font-small-bold-line-height) !important;
      margin-bottom: to-rem($font-small-bold-line-height);
      font-weight: 500;
    }



}

  
.footer_menu_lists {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}
  // @media (max-width:450px){
  //   .col-12 {
  //     width:100% !important
  //   }
  // }
  
}

/* Footernavigation */
.footer {

  .nav__list.level_1 {
    display: flex ;
    flex-wrap: wrap;
    text-align: left;
    justify-content: flex-start;
      .nav__item {
        display: grid !important;
        grid-template-columns: 1fr;
        width: 100%;
    }
    .nav__link {
      padding: 0 !important;
      margin: 0;
      text-align: left;
      width: fit-content;
      font-weight: $font-large-bold-font-weight;
      font-size: to-rem($font-small-font-size);
      line-height: to-rem($font-small-line-height);
      &:hover {
        color:$color-primary-dark !important;
        
        
      }
      &.active {
        color:$color-primary-dark !important;
      
      }
       span {
        border-bottom: 1px solid;
      }
     }
  }
  //  
  &__list {
    //
    & > i {
      font-size: to-rem($font-medium-line-height);
    }
    //
  }

  
}

.footer--2 img {
  max-width: 200px;
}

.footer--2 {
  display: flex;
  margin-top: 2rem;
}

.link--footer {
  font-family: Sarala, Helvetica, Arial;
  font-weight: 700 !important;
  font-size: clamp(1rem, 1rem + 2 * (100vw - 375px) / 1065, 1.125rem) !important;
  line-height: clamp(1.375rem, 1.375rem + 2 * (100vw - 375px) / 1065, 1.5rem) !important;
  border-bottom: 1px solid;
}

@media (max-width:767px) {
  .col-12.footer_menu_legel nav .nav__list.level_1 {
    display: flex !important;
    flex-wrap: nowrap;
    justify-content: space-between !important;
}
  
}

@media (max-width:400px) {
  .col-12.footer_menu_legel nav .nav__list.level_1 {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: space-between !important;
}

footer {
  padding-bottom: 10rem;
}
}


@media (max-width: 990px) {
 footer .nav__list.level_1 {
    display: grid !important;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    top: 5px;
}
footer .footer_menu_legel {
  height: 10px;
}
footer .footer_menu_legel ul.nav__list.level_1 {
  display: flex !important;
  position: relative;
  top: 300px;
}
}
