/* -------------------------------------------------------------
//  Fonts
// -----------------------------------------------------------*/
@font-face {
  font-family: 'Rubik';
  src: 
  local(''), url('../fonts/Rubik/rubik-v23-latin-regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: 
  local(''), url('../fonts/Rubik/rubik-v23-latin-italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
  font-display: swap;
}

@font-face {
    font-family: 'Rubik';
    src: 
    local(''), url('../fonts/Rubik/rubik-v23-latin-300.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rubik';
    src: 
    local(''), url('../fonts/Rubik/rubik-v23-latin-300italic.ttf') format('truetype');
    font-display: swap;
    font-style: italic;
    font-weight: 300;
  }   
  
  
  @font-face {
    font-family: 'Rubik';
    src: 
    local(''), url('../fonts/Rubik/rubik-v23-latin-500.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rubik';
    src: 
    local(''), url('../fonts/Rubik/rubik-v23-latin-500italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rubik';
    src: 
    local(''), url('../fonts/Rubik/rubik-v23-latin-600.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rubik';
    src: 
    local(''), url('../fonts/Rubik/rubik-v23-latin-600italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rubik';
    src: 
    local(''), url('../fonts/Rubik/rubik-v23-latin-700.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rubik';
    src: 
    local(''), url('../fonts/Rubik/rubik-v23-latin-700italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rubik';
    src: 
    local(''), url('../fonts/Rubik/rubik-v23-latin-800.ttf') format('truetype');
    font-style: normal;
    font-weight: 800;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rubik';
    src: 
    local(''), url('../fonts/Rubik/rubik-v23-latin-800italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 800;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rubik';
    src: 
    local(''), url('../fonts/Rubik/rubik-v23-latin-900.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rubik';
    src: 
    local(''), url('../fonts/Rubik/rubik-v23-latin-900italic.ttf') format('truetype');
    font-style: italic;
    font-weight: 900;
    font-display: swap;
  }

