.content-end {
  .features_item {
    justify-content: flex-end !important;
  }
}
.content-start {
  .features_item {
    justify-content: flex-start !important;
  }
}

.features_item{
    display: grid;
    justify-content: center;
    margin-top: 52px;

    .features_icon svg {
      width: 148px;
    height: 148px;
    fill:$color-accent !important;
    color:$color-accent;
    }
    .features_image{
      margin-top: 10px;
      margin-bottom: 25px;
      display: grid;
      justify-content: center;
      .features_icon{
        width: 180px;
        height: 180px;
        // background-color: $base-text-color;
        border-radius: 93.3333px;
        justify-content: center;
        display: grid;
        align-items: center;
        
        
      }
    }
    
    .features_title{
      justify-content: center;
        display: grid;
        text-align: center;
        max-width: 350px;
    }
    
  }

  