/* -------------------------------------------------------------
//  List
// -----------------------------------------------------------*/
.list {
  margin: 0;
  margin-bottom: to-rem($font-medium-line-height);
  padding: 0;

  &__item {
    font-size: to-rem($font-medium-font-size);
    line-height: to-rem($font-medium-line-height);
    margin: 0;
    margin-left: to-rem($font-medium-font-size);
    margin-bottom: to-rem($font-medium-line-height);
    padding: 0;
  }
}

.mod_article {
  ol {
    list-style: auto !important;

    li {
      font-size: to-rem($font-medium-font-size);
      line-height: to-rem($font-medium-line-height);
      margin: 0;
      margin-left: to-rem($font-medium-font-size);
      padding-bottom: to-rem(20);
    }
  }
  ul {
    margin-top: to-rem(20);
    margin-left: to-rem(45);
    li {
      font-size: to-rem($font-large-font-size);
      line-height: to-rem($font-large-line-height);
      margin: 0;
      margin-left: to-rem($font-large-font-size);
      padding-bottom: to-rem(20);
      list-style: disc;

      &::marker {
       // color: $color-accent;
      }
    }
  }
}
