/* -------------------------------------------------------------
//  Section
// -----------------------------------------------------------*/
.section-padding-top:after {
  margin-top: 0 !important;
}

.section-padding-bottom:before {
  margin-bottom: 0 !important;
}
.section {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  .inside.container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
    overflow: hidden;
  }
  &.first {
    border-top: none;
    &::before {
      margin-bottom: 0;
    }
    .container-fluid{
      padding: 0 !important;
    }
  }
  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    margin-bottom: to-rem($section-margin);
  }

  &::after {
    margin-top: to-rem($section-margin);
  }

  &--light {
    background-color: $section-background-light;
  }
  &--dark {
    background-color: $section-background-dark;
  }

  .section-pt {
    padding-top: to-rem($section-margin);
  }
  .section-pb {
    padding-bottom: to-rem($section-margin);
  }
}
.section-padding-none{
  border:none !important;
  &:before {
    padding: 0;
    margin: 0;
  }
  &:after {
    padding: 0;
    margin: 0;
  }
}
.bg-color-light-grey {
  background-color: $color-black-20;
  border-radius: $radius-top-right;
}
.contact__Wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 60px;
  gap: 32px;


  /* accent */

  border-radius: 10px;
  color: $color-white;
  position: relative;
  margin-top: 90;
  .contact__icon {
    position: absolute;
    top: -89px;
    /* margin: 0 auto; */
    display: grid;
    /* justify-content: center; */
    /* align-items: center; */
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    left: 0;

    img {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 168px;
        align-items: center;
    }
    }
  }

.margin_first_element {
  margin-bottom: 2rem;
}

.contao-cookiebar .highlight .cc-btn.success {
  background: $color-secondary !important;
  border-color: $color-secondary !important;
  color: #fff;
}


.contact__Wrapper .xxlarge a {
  color: white;
  border: none;
  font-size: to-rem($font-xxlarge-font-size);
  line-height: to-rem($font-xxlarge-line-height);
  margin-bottom: to-rem($font-xxlarge-line-height);
  font-weight: 700;
}

.contact__Wrapper .large.strong a {
  color: white;
  border: none;
  font-size: to-rem($font-large-font-size);
  line-height: to-rem($font-large-line-height);
  margin-bottom: to-rem($font-large-line-height);
  font-weight: 500;
}


.mod_article .ce_image .image_container img {
  max-width: fit-content;
  border-radius: 10px;
  height: auto;
}

.team__wrapper--text .accordion--team h6 {
  border-bottom: 1px solid $color-primary;
}
.team__wrapper--img {
  margin-bottom: 96px;
  img {
    border-radius: 10px;
  }

  @media (max-width:770px) {
    margin-bottom: 20px;
  }
}


.selected--nachrichten .mod_newslist.block {
  background: none;
  padding: 0;
}