/* -------------------------------------------------------------
//  Sentiment - Usage - Prominence – Interaction
//  default,..– bg, text,..– default, weak,..–default, hover,...
//  selected-text-default-hover
//
//  Usage – State
// ---------------------------------------------------------- */

// Root Font-Size
$root-font-size: $font-medium-font-size;

// Body
$body-background: $color-white;
$body-text: $color-black-90;
$body-font-family: $font-medium-font-family;
$body-font-size: $font-medium-font-size;
$body-line-height: $font-medium-line-height;

// Links
$link-color: $color-primary;
$link-color-dark: $color-white;

// Paragraphs
$paragraph-font-size: $font-medium-font-size;
$paragraph-line-height: $font-medium-line-height;
$paragraph-margin-bottom: $paragraph-font-size;

$paragraph-lead-font-size: $font-xlarge-font-size;
$paragraph-lead-line-height: $font-xlarge-line-height;
$paragraph-lead-margin-bottom: $paragraph-lead-font-size;

$paragraph-small-font-size: $font-small-font-size;
$paragraph-small-line-height: $font-small-line-height;
$paragraph-small-margin-bottom: $paragraph-small-font-size;



// Sections
$section-margin: $sizes-section;
$section-background-light: $color-info-light;
$section-background-dark: $color-black-20;

$radius-top-right:0px 140px 0px 0px;
$radius-bottom-right: 0px 0px 140px 0px;
$radius-bottom-left: 0px 0px 0px 140px;
$radius-top-bottom-full: 0px 40px;
$radius-top-bottom-half : 0px 20px;
$radius-full: 20px;
$radius-half: 10px;

// Accordion
$accordion-background-hover: $color-black-01;
$accordion-border-color: $color-black-40;
$accordion-font-color: $color-black-90;
$accordion-margin-bottom: $font-medium-line-height;

// Buttons
$button-font-size: $font-button-lead-font-size;
$button-line-height: $font-button-lead-line-height;
$button-margin-bottom: $font-button-lead-line-height;

$button-background-color: none;
$button-background-color-hover: none;
$button-border-color: $color-black-60;
$button-border-color-hover: $color-black-70;
$button-font-color: $color-black-70;
$button-font-color-hover: $color-black-90;

$button-primary-background-color: $color-primary;
$button-primary-background-color-hover: $color-primary-dark;
$button-primary-border-color: $color-primary;
$button-primary-border-color-hover: $color-primary-dark;
$button-primary-font-color: $color-white;
$button-primary-font-color-hover: $color-white;

$button-link-background-color: none;
$button-link-background-color-hover: none;
$button-link-border-color: none;
$button-link-border-color-hover: none;
$button-link-font-color: $color-black-70;
$button-link-font-color-hover: $color-black-90;

// Checkbox
$checkbox-border-color: $color-black-60;
$checkbox-size: $font-medium-line-height;

// Contact Form
$textfield-input-background-color: $color-white;
$textfield-input-border-color: $color-black-60;
$textfield-input-font-family: $font-medium-font-family;
$textfield-input-font-size: $font-medium-font-size;

$textfield-label-font-family: $font-xsmall-font-family;
$textfield-label-font-size: $font-xsmall-font-size;
$textfield-label-line-height: $font-xsmall-line-height;

$textfield-margin-bottom: $font-medium-line-height;

// Footer
$footer-background-color: $color-black-10;
$footer-text-color: $color-black-90;

// Header
$header-background-color: $color-white;

// Navigation

$nav-container-background-color: $color-white;

$nav-list-font-color: $color-primary;
$nav-list-font-family: $font-button-font-family;
$nav-list-font-size: $font-button-font-size;
$nav-list-font-weight: $font-large-font-weight;
$nav-list-line-height: $font-button-line-height;

// Radio-Button
$radio-border-color: $color-black-60;
$radio-size: $font-medium-line-height;

// Slider
$slider-background-color: $color-black-20;
$slider-icon-color: $color-primary;
$slider-bullet: $color-primary-light;
$slider-bullet-active: $color-primary;
$slider-frame-color: $color-primary;
$slider-frame-background-active: $color-white;