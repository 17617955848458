/* -------------------------------------------------------------
//  Button
// -----------------------------------------------------------*/
.btn {
  align-items: center;
  border-radius: 64px;
  text-decoration: none !important;
  cursor: pointer;
  display: inline-flex;
  font-family: $font-medium-font-family !important;
  font-size: to-rem($button-font-size) !important;
  font-weight: 700;
  line-height: to-rem($button-line-height) !important;
  margin-bottom: to-rem($button-margin-bottom) !important;
  text-align: center;
  text-decoration: none;
  border: none !important;
    
  // Flex Hack
  & > * {
    //margin-right: 0.5ch;
  }

  & > i ,span {
    font-size: to-rem($button-font-size);
  }

  &:hover {
    color: $color-primary-light;
  }

}

.btn-link {
  border: none;
}

.btn-arrow:before {
  font-family: 'iconfont';
  width: 40px;
  height: 40px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: to-rem(40);
  line-height: to-rem($button-line-height);
}


.btn--secondary::before {
  color: $color-secondary !important;
}

.btn--secondary_arrow {
 
  color: $color-white !important;
}

.btn--secondary_arrow-before:before {
  color: $color-white !important;
}

.btn.btn--secondary.btn--secondary_arrow.btn--secondary_arrow-before{
 
  &:hover {
   
    color: $color-primary !important ;
    &:before {
    
      color: $color-primary !important ;
    }
  }

 
}

.btn.btn--primary.btn--primary_arrow-before{
 
  &:hover {
    
    color: $color-primary !important ;
    &:before {
      color: $color-primary !important ;
    }
  }

}

