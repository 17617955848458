
.newslist{
    margin: 0 !important;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    .newslist__item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 30px;
          gap: 40px;
          margin-left: 0;
          width: 48%;
          
          /* white */
          
          background: #FFFFFF;
          /* elevation-light-05 */
          
          box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.04), 0px 16px 24px rgba(96, 97, 112, 0.16);
          border-radius: 20px;
          flex-grow: 1;
      
          &:focus,&:active,&:visited, &:hover {
            color:$color-primary;
            .h4, h4 , p , .xlarge {
              color: $color-primary;

            }
          }
         
       
        }
        .newsitem__link {
          text-decoration: none;
        }
        .newslist__item--text{
            align-items: flex-start;
            padding: 0px;
            gap: 20px;
            color: $color-black-90;
            .h4, h4 , p , .xlarge {
              color: $color-black-90;

            }
            .news__datum {
              margin-bottom: 20px;
          }
        }

       
    }



    .mod_newslist.block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 60px;
      gap: 50px;
      width: 100%;
      background: #EEEFF5;
      border-radius: 10px;

      h2 {
        font-style: italic;
      }
  }