/* -------------------------------------------------------------
//  Accordion
// -----------------------------------------------------------*/
.Accordion {
  //margin-top: 2.5rem;
  //margin-bottom:2.5rem ;
  padding: 0;
  overflow: hidden;
  min-height: 140px;
    display: grid;
}

.Accordion h6 {
  margin-top: 0;
  height: 72px;
  border-top: 1px solid $color-primary;
  display: flex;
}
.Accordion.focus {
  border-color: $color-primary;
}
.Accordion > * + * {
 // border-top: 1px solid $color-primary;
}
.Accordion-trigger {
  background: none;
  border-style: none;
  color: $color-primary;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  position: relative;
  text-align: left;
  width: 100%;
  outline: none;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
}
.Accordion-trigger:focus,
.Accordion-trigger:hover {
  cursor: pointer;
}
.Accordion button::-moz-focus-inner {
  border: 0;
}
.Accordion-title {
  color: $color-primary;
  display: flex;
  pointer-events: none;
  outline: none;
  align-items: center;
  margin-top: 0 !important;
  justify-content: space-between;
  width: 100%;
}
.Accordion-trigger:focus .Accordion-title {
  /*border-color: hsl(216, 94%, 73%);*/
}
.Accordion-icon {
  speak: none;
  pointer-events: none;
  // position: absolute;
  // right: 26px;
  // top: 24px;
  width: min-content;
  list-style-position: inside;
  list-style-position: inside;
}
.Accordion-icon::before {
  color:$color-primary;
  content: "\e906";
  font-family: 'iconfont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  width: max-content;
  font-size: 24px;
  font-weight: 700;
}
.Accordion-icon::after {
  color:$color-primary;
  font-family: 'iconfont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\e909';
  display: block;
  width: max-content;
  font-size: 24px;
  font-weight: 700;
}


.Accordion-trigger:focus .Accordion-icon,
.Accordion-trigger:hover .Accordion-icon {
}

.Accordion-trigger[aria-expanded='true'] .Accordion-icon {
  speak: none;

}
.Accordion-trigger[aria-expanded='false'] .Accordion-icon::after {
  display: none;
}
.Accordion-trigger[aria-expanded='true'] .Accordion-icon::before {
  display: none;
}

.Accordion-panel {
  margin: 0;
  color: $color-black-90;
  padding: 0;
  padding-left: 20px
}
.Accordion-panel[hidden] {
  display: none;
}
.Accordion-panel ul li {
  //margin-bottom: 1em;
  color: $color-black-90;
  //padding-left: 30px;
}
.Accordion-panel ul li a {
  color: $color-primary;
  font-weight: 500;
  text-decoration: none;
}
.accordion--padding>div {
  padding: 20px 60px 15px 60px !important;
}

@media (max-width:990px) {
 
  .Accordion-trigger.accordion-open {
   
    padding-top: 0;
    padding-bottom: 0;
}
}