/* -------------------------------------------------------------
//  Navigation
// -----------------------------------------------------------*/
a.skip-nav-link {
  display: none;
}
.nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 80px;
  &__container {
    // Mobile First Dropdown Navigation

    visibility: hidden;
    opacity: 0;

    display: grid;
    

    inset: 0 0 0 0;
    position: fixed;

    transition: all 500ms ease-in-out;

    @media (min-width: 990px) {
      visibility: visible;
      opacity: 1;
      background: none;
      position: absolute;
      display: flex;
      right: 16px;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }  
    // Desktop Viewport
    @media (min-width: 1200px) {
      visibility: visible;
      opacity: 1;
      background: none;
      position: absolute;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
   
    //navbar__container toggle
    &.is-open {
      visibility: visible;
      opacity: 1;
      overflow: auto;
      background: white;

      li.nav__item.submenu {
        border-bottom: 1px solid;
      }

      .nav__list.level_2 {
        visibility: hidden;
        height: 0;
        transition: $transition--slow;
      }

      .nav__link {
        display: flex;
      justify-content: center;
      align-items: center;
    }

    .level_1 .nav__item .nav__link.submenu:after {
      height: unset !important;
    }
    }
  }

  // navbar__list ul
  &__list {
    position: relative;
    @media (max-width:990px) {
      &.level_1 {
        display: grid !important;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        top:80px;
      }
      
      .level_2 {
        display: block;
        width: 100% !important;
      }
    }
    
    // Desktop Viewport
    @media (min-width: 990px) {
    //   li.nav__item.submenu.trail.page--accent-3.last {
    //     min-width: 150px;
    //     overflow: hidden;
    // }

    
      .nav__item {
        .nav__list {
          display: none;
        }
        &:hover {
          .nav__list {
            display: grid;
            position: absolute;
            top: 80px;
          }
        }
      }
    }

    &.active {
      
    }
    .nav__item.last:hover .nav__list.level_2 {
      /* min-width: 150px; */
      overflow: hidden;
      right: 0;
  }
  }
  // navbar__list li
  &__item {
    text-align: center;
    // Desktop Viewport
    @media only screen and (min-width: 900px) {
      display: inline;
      ul.nav__list.level_2 {
        box-shadow: 0 4px 8px rgb(0 69 111 / 4%), 0 4px 8px rgb(0 69 111 / 4%) !important;
       }
    }
  }
  // navbar__list li a
  &__link {
    color: $nav-list-font-color !important;
    font-family: $nav-list-font-family;
    font-size: to-rem($font-large-font-size);
    font-weight: $nav-list-font-weight;
    line-height: to-rem($font-large-line-height);

    display: block;
    text-decoration: none;
    margin: 0;
    padding: 15px;
    position: relative;
    margin: 0 auto;
    text-align: center ;
    // Desktop Viewport
    @media only screen and (min-width: 990px) {
      display: inline-block;
      padding: 0;
      margin: 0 7px 0 10px;
      
    }
   
    &:hover, &:focus {

      // Desktop Viewport
      @media only screen and (min-width: 990px) {
        color: $color-primary ;
      }
      color: $color-primary ;
    }

    &.active {

      // Desktop Viewport
      @media only screen and (min-width: 990px) {
        // Desktop Viewport
        color: $color-primary ;
      }
      color: $color-primary ;
    }
  }
}


// Burger Button
.nav__toggle-button {
  cursor: pointer;
  height: 20px;
  position: relative;
  width: 20px;
  z-index: 15;
  transition: top $transition--slow;
}

/* Burgerbutton */
.line {
  background-color: $color-primary;
  display: block;
  height: 2px;
  position: absolute;
  width: 20px;
  border-radius: 2px;
}

.line--top {
  left: 0;
  top: 4px;
  width: 13px;
}
.line--middle {
  left: 0;
  top: 11px;
}
.line--bottom {
  left: 0px;
  top: 18px;
  width: 13px;
}

.nav__toggle-button.toggle .line--top {
  animation: line-top-animation 400ms 0s 1 forwards;
}

@keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}

.nav__toggle-button.toggle .line--bottom {
  animation: line-bottom-animation 400ms 0s 1 forwards;
}


@keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}

.nav__toggle-button.toggle .line--middle {
  animation: line-middle-animation 400ms 0s 1 forwards;
}
@-webkit-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}

@keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}

.nav__toggle-button .line--top {
  animation: line-top-animation-back 400ms 0s 1 forwards;
}

@keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}
.nav__toggle-button .line--bottom {
  animation: line-bottom-animation-back 400ms 0s 1 forwards;
}

@keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}
.nav__toggle-button .line--middle {
  animation: line-center-animation-back 400ms 0s 1 forwards;
}

@keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}

